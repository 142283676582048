$(document).ready(function () {
    $('#product-carousel').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 5,
            }
        }
    })
})
$(document).ready(function () {
    $('#article-carousel').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 5,
            }
        }
    })

    $('#slide-carousel').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        }
    })

    $('.section-carousel').each(function () {
        var _this = $(this);
        var instant = '#' + _this.attr('id');

        $(instant).owlCarousel({
            loop: true,
            margin: 20,
            responsiveClass: true,
            dots: false,
            nav: true,
            responsive: {
                 414: {
                    items: 2,
                },
                480: {
                    items: 2,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 5,
                }
            }
        })
    })

})
